* {
  scrollbar-width: auto;
  scrollbar-color: #FF5714 orange;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1.5rem;
  height: 2rem;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #FF5714;
  border-radius: 1rem;
  border: 3px solid white;
}





/*
  animation-name: example;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;

  animation: example 5s linear 2s infinite alternate;

  animation-fill-mode: both;

*/


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
