body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.max-width-con{
  max-width: 1200px;
  margin: 0 auto;
  background-color: bisque;
}



.title{
  font-size: 3rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: var(--orange);
}

.line{
  width: 100%;
  height: 1px;
  background-color: var(--lightBlue);
}
